<template>
  <div class="lottery-tema">
    <div class="header">
      <div class="title-box">
        <p class="title">特码分析</p>
        <intro-default cont=""></intro-default>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="days">
        <li :class="{active: option.value === day}" v-for="option in days" :key="option.value" @click="day = option.value">{{ option.label }}</li>
      </ul>
      <div class="table">
        <el-table :data="data" border style="width: 100%" highlight-current-row header-cell-class-name="table-header-cell" cell-class-name="table-cell" row-class-name="table-row" size="small" row-key="id" id="table">
          <el-table-column label="属性" align="center" prop="number"></el-table-column>
          <el-table-column label="当前遗漏" align="center" prop="now_missing" sortable></el-table-column>
          <el-table-column label="最大遗漏" align="center" prop="max_missing" sortable></el-table-column>
          <el-table-column label="平均遗漏" align="center" prop="avg_missing" sortable></el-table-column>
          <el-table-column label="最大长龙" align="center" prop="max_long" sortable></el-table-column>
          <el-table-column label="最大每日开出" align="center" prop="max_appear" sortable></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'

export default {
  name: 'LotteryTema',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data () {
    return {
      data: [],
      loading: null,
      days: [
        {
          label: '今天',
          value: 0
        },
        {
          label: '昨天',
          value: 1
        },
        {
          label: '7日',
          value: 7
        },
        {
          label: '30日',
          value: 30
        },
        {
          label: '90日',
          value: 90
        }
      ],
      day: 0
    }
  },
  // computed: {

  // },
  watch: {
    code: function (val, oldVal) {
      this.requestGetHistory()
    },
    day: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted () {
    this.requestGetHistory()
  },
  methods: {
    refresh () {
      this.requestGetHistory()
    },
    requestGetHistory () {
      this.showTableLoading()
      this.$api.specialCode(this.code, this.day).then((res) => {
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading () {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading () {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.lottery-tema {
  > .header {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      border-bottom: thin $color-border solid;

      > .title-box {
        display: flex;
        align-items: center;
        height: 49px;

        > .title {
          font-size: 20px;
          font-weight: 400;
          color: #222;
        }
      }
    }
    .lz_cont {
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 0;
      box-sizing: border-box;
    }

  .days {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;
    display: flex;
    align-items: center;

    > li {
      cursor: pointer;
      padding: 0 8px;
      min-width: 70px;
      height: 24px;
      background: #E3E3E5;
      border-radius: 4px;
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      color: #939395;
      &.active {
        background: #FA3E3E;
        color: #fff;
      }

      & + li {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style>
.lottery-tema>.table {
  margin-top: 20px;
}

.lottery-tema>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-tema>.table .table-header-cell>.cell {
  padding: 0;
}

.lottery-tema>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 40px;
}

.lottery-tema>.table .table-cell>.cell {
  padding: 0;
}

.lottery-tema>.table .table-row:hover>td,
.lottery-tema>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
